<template>
    <div id="supp">
        <div class="row">
            <div class="col-12">
                <div class="container">
                    <hr style="color:cornflowerblue;height: 5px;"/>
                </div>
                <div>
                    <font-awesome-icon :icon="['fas', 'headset']" style="color:cornflowerblue;" size="lg"/>
                    <span class="p-2 un">Support &nbsp;&nbsp;&nbsp;&nbsp;</span>
                </div>
            </div>
        </div>
        <div class="row pt-4">
            <div class="col-4">
                <div class="card">
                    <div class="bg-danger">
                        <div class="card-title text-white p-3 fw">Request</div>
                        <div class="card=body">
                            <div class="card-title text-center fs-5 text-white fw">Delete Account</div>
                        </div>
                        <div class="p-2">
                            <div class="text-white">Delete account and Data.</div>
                        </div>
                    </div>
                    <div class="d-grid gap-2 p-2 g3">
                        <button class="btn btn-primary">Request Form</button>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="card">
                    <div class="bg-primary">
                        <div class="card-title text-white p-3 fw">Post</div>
                        <div class="card=body">
                            <div class="card-title text-center fs-5 text-white fw">Report a problem</div>
                        </div>
                        <div class="p-2">
                            <div class="text-white">&nbsp;</div>
                        </div>
                    </div>
                    <div class="d-grid gap-2 p-2 g3">
                        <button class="btn btn-primary">Report Form</button>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="card">
                    <div class="bg-dark">
                        <div class="card-title text-white p-3 fw">Manual</div>
                        <div class="card=body">
                            <div class="card-title text-center fs-5 text-white fw">&nbsp;</div>
                        </div>
                        <div class="p-2">
                            <div class="text-white">&nbsp;</div>
                        </div>
                    </div>
                    <div class="d-grid gap-2 p-2 g3">
                        <button class="btn btn-primary">Open Manual</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>
<script>
export default{

}
</script>
<style>
    .g3{
       padding-left: 30px !important;
       padding-right: 30px !important; 
    }
    .fw{
        font-weight: bold;
    }
    .un{
        font-weight: bold;
        text-decoration: underline;
        text-decoration-line: 2px;
        color: cornflowerblue;
        text-underline-offset: 7px;
        text-decoration-thickness: 2px;
    }
</style>