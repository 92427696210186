<template>
    <div class="top-color-ground">
        <nav class="navbar navbar-expand-lg">
            <div class="container-fluid">
                <a class="navbar-brand" href="#">
                    <img src="../../assets/logo_web.png" alt="" width="200" class="d-inline-block align-text-top">
                </a>
                <div class="collapse navbar-collapse">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    </ul>
                    <div class="d-flex" role="search">
                       <button class="btn btn-default" @click="direct()">
                            <font-awesome-icon :icon="['fas', 'headset']" class="top-color mr-2" size="sm"/>
                            <span class="fontW top-color">SUPPORT</span>
                       </button>
                       <button class="btn btn-dark" @click="signInOpen()">
                         <span class="fontW text-white">SIGN IN</span>
                       </button>
                    </div>
                </div>
            </div>
        </nav>
        <signinform ref="sModal"></signinform>
        <div class="container">
            <div class="row" style="margin-top:100px;">
                <div class="col-3">
                    <div class="card" style="background-color:#336998;border-bottom-left-radius: 20px;border-bottom-right-radius: 20px;">
                        <div class="card-body p-0">
                            <div class="calen">
                                <div class="row">
                                    <div class="col-12 text-center fs-1 text-white" style="padding-top:50px !important;padding-bottom: 50px !important;">
                                        SUPPORT
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-7">
                    <div class="row" style="text-align:right;">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-4"></div>
                                <div class="col-3" style="text-align:right;">
                                    <font-awesome-icon :icon="['fas', 'phone']" class="top-color mr-2" size="2x"/>
                                </div>
                                <div class="col-5" style="text-align:left;">
                                    <div class="fs-4 text-white">
                                        +662 231 8089<br/>
                                        +6693 516 5449
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4"></div>
                                <div class="col-3">
                                    <font-awesome-icon :icon="['fas', 'envelope']" class="top-color mr-2" size="2x"/>
                                </div>
                                <div class="col-5" style="text-align:left;">
                                    <div class="fs-4 text-white">
                                        support@wealtzland.com
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import signinform from "../tipexpress/signinform.vue" 
export default {
    components:{
        signinform
    },
    methods:{
        signInOpen(){
            this.$refs.sModal.showModal();
        },
        direct(){
            window.location = "/support";
        }
    }
}
</script>
<style scoped>
 .f-small{
  font-size: 14px;
 }
 .fontW{
    font-weight: normal;
    margin-left: 7px;
    font-size: 12px;
 }
 .top-color{
    color: #FFCF5E;
 }
 .top-color-ground{
    background: #005C8D;
    position: relative;
    height: 500px;
    display: block;
 }
 .text-left{
    text-align: left;
 }
</style>