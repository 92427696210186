<template>
    <b-modal id="tkilogin" title="BootstrapVue">
        <p class="my-4">Hello from modal!</p>
    </b-modal>
    <!--div class="modal fade" ref="staticBackdrop" id="staticBackdrop"  data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
         <div class="modal-dialog modal-dialog-centered">
            <div class="container">
                xxxxx
            </div>
        </div>
    </div-->
</template>
<script>
export default {
    methods:{
        showModal(){
            //this.$root.$emit('bv::show::modal', 'tkilogin');
            
        },
        hide(){

        }
    }
}
</script>