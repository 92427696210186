<template>
    <div class="bg-light">
        <tkiatop></tkiatop>
        <div class="container">
            <supportWidget></supportWidget>
        </div>
    </div>
</template>
<script>
import tkiatop from "../tipexpress/tkiasupport.vue"
import supportWidget from '../tipexpress/widgets/supportWidget.vue'
export default {
    components:{
        tkiatop,
        supportWidget,
    }
}
</script>
<style scoped>
.helpsupport{
   font-weight: bold;
}
</style>
